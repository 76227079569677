<template>
  <v-card flat class="pa-2">
    <v-card-title>
      <div>
        <div class="dark-grey-text bold-text">Notification settings</div>
        <div class="text-muted bodyFont">
          We'll never spam you. We hate it too!
        </div>
      </div>
    </v-card-title>
    <v-card-text class="py-5">
      <div v-if="loading">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-row align="center">
          <v-col v-if="errorMessage" cols="12">
            <vue-inline-alert
              :message="errorMessage"
              message-type="error"
            ></vue-inline-alert>
          </v-col>
          <v-col cols="8"> </v-col>
          <v-col class="bold-text" cols="2"> Email </v-col>
          <v-col class="bold-text" cols="2"> Push </v-col>
        </v-row>
        <v-row align="center" v-for="(item, index) in settingList" :key="index">
          <v-col class="font-size-h4" cols="8">
            <div class="bold-text pb-2">
              {{ settingsDesc[item] ? settingsDesc[item].title : "" }}
            </div>
            <div class="text-muted bodyFont">
              {{ settingsDesc[item] ? settingsDesc[item].description : "" }}
            </div>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              :id="`emailSettingCheckbox${index}`"
              hide-details
              :disabled="item === 'essentials'"
              v-model="settings[item].email"
            ></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              :id="`pushSettingCheckbox${index}`"
              hide-details
              :disabled="item === 'essentials'"
              v-model="settings[item].push"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-if="!loading">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!fromPage"
        @click="$emit('close')"
        text
        :disabled="loading"
        color="primary"
        id="cancelNotificationSettingBtn"
        class="text-transform-none"
        >Cancel</v-btn
      >
      <v-btn
        @click="updateSettings"
        depressed
        :loading="loading"
        :disabled="loading || !!errorMessage"
        id="updateNotificationSettingBtn"
        color="primary"
        class="text-transform-none"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_NOTIFICATIONS_SETTINGS,
  UPDATE_NOTIFICATIONS_SETTINGS,
} from "@/store/users/settings.module.js";
import { mapGetters, mapMutations } from "vuex";
import { isEmpty } from "@/core/services/helper.service.js";
export default {
  props: {
    fromPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updateUUID: "",
      attrs: {},
      settingsDesc: {
        product_news: {
          title: "Product news",
          description:
            "New features, major changes and latest news about TestApp.io",
        },
        promotions: {
          title: "Promotions",
          description: "Offers, discounts and updates about our plans",
        },
        recommendations: {
          title: "Recommendations",
          description:
            "Tips and tricks to get you going and leverage TestApp.io to the max",
        },
        essentials: {
          title: "Essentials",
          description:
            "Enables essential services and functionality, including identity verification, service continuity and site security. Opt-Out is not available",
        },
      },
      errorMessage: null,
      settings: {
        uuid: "",
        product_news: {
          email: true,
          push: false,
        },
        promotions: {
          email: false,
          push: false,
        },
        recommendations: {
          email: false,
          push: false,
        },
        essentials: {
          email: true,
          push: true,
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getNotificationsSetting: "getNotificationsSetting",
    }),
    settingList() {
      return Object.keys(this.settings).splice(1, 5);
    },
  },
  watch: {
    getNotificationsSetting(val) {
      if (val) {
        val.essentials = {
          email: true,
          push: true,
        };
        this.settings = val;
      }
    },
  },
  mounted: function () {
    if (this.getNotificationsSetting && !this.fromPage) {
      this.settings = this.getNotificationsSetting;
      this.settings.essentials = {
        email: true,
        push: true,
      };
    }
    this.populateInitialData();
  },
  methods: {
    ...mapMutations({
      updateNotificationsSetting: "updateNotificationsSetting",
      setAuthMessage: "setAuthMessage",
    }),
    populateInitialData() {
      if (this.fromPage) {
        if (this.loading) return;
        this.loading = true;
        this.$nextTick(() => {
          if (isEmpty(this.$route.params)) {
            this.$router
              .push({
                name: "dashboard",
                query: { navigate_to: "notification_settings" },
              })
              .catch();
            this.loading = false;
            this.setAuthMessage({
              message: "Signin to update your notification settings",
              type: "error",
            });
          } else {
            this.loading = false;
            this.getSettingInfo();
          }
        });
      }
    },
    getSettingInfo() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_NOTIFICATIONS_SETTINGS, this.$route.params.uuid)
        .then(({ data }) => {
          this.loading = false;
          this.settings = { ...data.data.settings };
          this.settings.essentials = {
            email: true,
            push: true,
          };
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    },
    updateSettings() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(UPDATE_NOTIFICATIONS_SETTINGS, {
          settings: this.settings,
        })
        .then((response) => {
          this.loading = false;
          this.updateNotificationsSetting(this.settings);
          this.$emit("close");
          this.notifyUserMessage(
            response.message || "Successfully updated notification settings"
          );
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message || "Something went wrong");
        });
    },
  },
};
</script>

<style scoped></style>
