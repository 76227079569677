<template>
  <div>
    <div class="fill-width align-self-start">
      <v-card
        color="primary"
        flat
        class="d-flex align-center justify-center fill-width"
        height="110"
        tile
      >
        <a @click="$router.push('dashboard')" class="text-center pa-5">
          <img
            src="/media/logos/logo_light_155x30.png"
            class="max-h-70px"
            alt
          />
        </a>
      </v-card>
    </div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-row justify="center" align="center">
            <v-col cols="12" md="9">
              <manage-settings from-page></manage-settings>
            </v-col>
            <v-col class="text-center" cols="12" md="3">
              <v-card
                rounded
                class="py-3"
                style="border-radius: 10%"
                flat
                color="primary"
              >
                <v-icon color="white" size="100">mdi-rocket</v-icon>
                <v-card-text>
                  <div class="py-3 titleFont bold-text white--text">
                    <div class="py-3">Share your app today!</div>
                  </div>
                  <v-btn
                    depressed
                    color="white"
                    @click="$router.push({ name: 'dashboard' }).catch()"
                    class="font-weight-bold primary--text"
                    >Access Portal</v-btn
                  >
                </v-card-text>
              </v-card></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>
import ManageSettings from "@/view/components/User/Notification/ManageSettings.vue";
import Footer from "@/view/layout/footer/Footer.vue";
export default {
  components: {
    ManageSettings,
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>